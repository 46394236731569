//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, computed, inject, useContext, ref } from '@nuxtjs/composition-api'
import useCurrentLanguage from '~/composables/useCurrentLanguage'
import useFaroIntegration from '~/composables/useFaroIntegration'
import useLoginInformation from '~/composables/useLoginInformation'

export default defineComponent({
  name: 'Breadcrumb',
  setup () {
    const { $cms } = useContext()
    const { currentLanguage, currentTranslatedSlug } = useCurrentLanguage()
    const defaultPageName = inject('getName')()
    const pageName = currentTranslatedSlug.value.name || defaultPageName
    const { userIsLoggedIn } = useLoginInformation()
    const translations = ref([])
    const { faroError } = useFaroIntegration()

    const slugsToHide = ['service', 'sortiment', 'product-range', 'info', 'unternehmen', 'company']

    const breadcrumbs = computed(() => {
      if (currentTranslatedSlug.value && translations.value.length) {
        // get slug from current language
        const path = currentTranslatedSlug.value.path.split('/').filter(item => item !== '')

        const pathToDisplay = path.filter(crumb => !slugsToHide.includes(crumb) && !(path.length === 1 && crumb === 'home') && crumb !== '')
        return pathToDisplay.map((crumb, index) => {
          const crumbPath = `/${currentLanguage.value}/${path.slice(0, index + 1).join('/')}`
          const isLastPathItem = (pathToDisplay.length === index + 1)

          return ({
            path: crumbPath,
            name: getCrumbName(crumb, isLastPathItem)
          })
        })
      } else {
        return []
      }
    })

    function getCrumbName (crumb, isLastPathItem) {
      const translatedCrumb = translations.value.find(t => t.name === crumb)
      if (translatedCrumb) {
        return translatedCrumb.dimension_value || translatedCrumb.value
      } else if (isLastPathItem && pageName) {
        return pageName
      }
      return crumb.replace(/-/g, ' ')
    }

    async function getBreadcrumbTranslations () {
      try {
        const reply = await $cms.query({
          type: 'datasource_entries',
          options: {
            datasource: 'breadcrumb-translation',
            dimension: currentLanguage.value
          }
        })
        translations.value = reply.data.datasource_entries
      } catch (err) {
        faroError(err, 'c-breadcrumbs-getBreadcrumbTranslations')
      }
    }

    getBreadcrumbTranslations()

    return {
      breadcrumbs,
      currentLanguage,
      userIsLoggedIn
    }
  }
})
